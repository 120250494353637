import { CRMLabels } from '@dtx-company/true-common/src/types/crmThirdParty'
import {
  DemoModalGeolocation,
  DemoModalHubspotCRM,
  LockModalCoreProPlusDomains,
  LockModalFCProScanDestination,
  LockModalFPProFontStyles,
  LockModalFPProFonts,
  LockModalFPProHexColors,
  LockModalFPProMailchimpCRM,
  LockModalFPProPlusLinkLevelStyling,
  LockModalFPProPlusPageTemplates,
  LockModalFPProPlusZapierCRM,
  LockModalProGraphicBG,
  ProPlusFlowteams
} from '@app/code/src/assets'
import { PlanDisplayNames, PlanType } from '@dtx-company/true-common/src/types/planType'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { UrlObject } from 'url'
import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import flowcodeEvents from '@dtx-company/inter-app/src/event-tracking/events/flowcode'
import flowlyticsEvents from '@dtx-company/inter-app/src/event-tracking/events/flowlytics'
import flowpageEvents from '@dtx-company/inter-app/src/event-tracking/events/flowpage'
import miscEvents from '@dtx-company/inter-app/src/event-tracking/events/miscellaneous'

export interface LockedFeaturesUpsellContentType {
  header?: string
  customHeadingText?: string
  src?: string
  alt?: string
  height?: number
  width?: number
  bg?: string
  hideCompanies?: boolean
  lottie?: {
    url: string
  }
  tooltipContent?: {
    id: string
    title: string
    body: string
    placement:
      | 'top'
      | 'right'
      | 'bottom'
      | 'left'
      | 'bottom-end'
      | 'bottom-start'
      | 'left-end'
      | 'left-start'
      | 'right-end'
      | 'right-start'
      | 'top-end'
      | 'top-start'
      | undefined
    trialVariant?: {
      title: string
      body: string
    }
  }
  trackingCall?: () => void
  [PlanType.PRO_FLEX]?: {
    title: string
    description: (codeLimit?: number, pageLimit?: number, isAdmin?: boolean) => string
    src: string
    href?: (isAdmin?: boolean) => UrlObject | undefined
    cta?: (isAdmin?: boolean) => string | undefined
  }
  [PlanType.ENTERPRISE]?: {
    title: string
    description: (codeLimit?: number, pageLimit?: number, isAdmin?: boolean) => string
    src: string
    href?: (isAdmin?: boolean) => UrlObject | undefined
    cta?: (isAdmin?: boolean) => string | undefined
  }
}

export const LockedFeaturesUpsellContent: {
  [key in string]?: LockedFeaturesUpsellContentType
} = {
  ['view:page_fonts']: {
    header: 'Unlock more fonts for advanced customization on Flowpage',
    src: LockModalFPProFonts,
    bg: LockModalProGraphicBG,
    trackingCall: flowpageEvents.userClickedGatedFlowpageFontOptions
  },
  ['view:page_style_options']: {
    header:
      'Unlock additional color and font styling options for your links to better align your Flowpage with your brand',
    src: LockModalFPProFontStyles,
    bg: LockModalProGraphicBG
  },
  ['view:smart_rules']: {
    header: 'Smart codes can scan to different places based on the time, day or device type',
    src: LockModalFCProScanDestination,
    bg: LockModalProGraphicBG,
    trackingCall: flowcodeEvents.userClickedGatedSmartRules
  },
  ['view:flowcode_eye_branding_toggle']: {
    header: 'Remove the Flowcode branding on the eye of your Flowcode',
    src: '/graphics/lockModal/fc-whitelabel.svg',
    bg: LockModalProGraphicBG,
    tooltipContent: {
      id: 'remove-whitelabel',
      title: 'You can remove the Flowcode branding from the eye of your code.',
      body: 'The privacy policy link at the bottom of the code will be retained for security purposes',
      placement: 'right',
      trialVariant: {
        title: 'Why can’t I use this feature on trial?',
        body: 'We can’t support this feature for users on trial for technical reasons. Upgrade now to use this feature.'
      }
    },
    trackingCall: () => {
      fireAnalyticsEvent('CreateFlowcode_Clicked_Branding', {
        upsell: true
      })
    }
  },
  ['view:remove_footer_branding_slot']: {
    header: 'Take maximum control of your branding',
    src: '/graphics/lockModal/fp-pro-whitelabel-lg.svg',
    bg: LockModalProGraphicBG,
    lottie: {
      url: 'https://assets3.lottiefiles.com/packages/lf20_rysenydb.json'
    },
    tooltipContent: {
      id: 'pro-flowpage-remove-footer',
      title: 'Remove Flowpage Whitelabel',
      body: 'Upgrade to remove the Flowcode branding from the bottom of your Flowpage',
      placement: 'right'
    }
  },
  ['view:tv_code_toggle']: {
    header: `for codes optimized for TV`,
    src: '/images/create-flowcode/advanced-video.png',
    bg: ''
  },
  ['view:mailchimp_crm_option']: {
    header: 'Take automation to the next level with advanced integration capabilities',
    src: LockModalFPProMailchimpCRM,
    bg: LockModalProGraphicBG,
    tooltipContent: {
      id: 'mailchimp-crm-tooltip',
      title: 'Mailchimp CRM',
      body: 'Upgrade to connect CRM data that you collect with Flowpage directly to your Mailchimp instance.',
      placement: 'right'
    },
    trackingCall: () =>
      flowpageEvents.userClickedGatedCRMIntegrations({ variant: CRMLabels.MAILCHIMP })
  },
  ['view:hex_color_code']: {
    header: '',
    customHeadingText: `Unlock Hex color options`,
    src: '/graphics/lockModal/fc-pro-code-limit3-2x.png', // using same image as pro limit
    height: 663,
    width: 305,
    bg: '#242424',
    hideCompanies: true,
    tooltipContent: {
      id: 'hex-color-code',
      title: 'Hex colors',
      body: 'Upgrade to select colors for your Flowcode that match your brand.',
      placement: 'right'
    },
    trackingCall: flowcodeEvents.Flowcode_LoggedIn_Generator_Clicked_ProUpsellHexColors
  },
  ['view:hex_color_page']: {
    header: 'Get access to advanced Flowpage design customizations with hex colors',
    src: LockModalFPProHexColors,
    bg: LockModalProGraphicBG,
    tooltipContent: {
      id: 'fp-hex-colors',
      title: 'Hex colors',
      body: 'Upgrade to select colors for your Flowpage that match your brand.',
      placement: 'right'
    },
    trackingCall: flowpageEvents.Flowpage_PageProfile_Clicked_ProUpsellHexColors
  },
  ['view:free_text_center']: {
    trackingCall: flowcodeEvents.Flowcode_Generator_Clicked_ProUpsellFreeTextCenter
  },
  ['view:zapier_crm_option']: {
    header: 'Take automation to the next level with Zapier integration capabilities',
    src: LockModalFPProPlusZapierCRM,
    bg: LockModalProGraphicBG,
    tooltipContent: {
      id: 'zapier-crm-tooltip',
      title: 'Zapier CRM',
      body: 'Upgrade to connect CRM data that you collect with Flowpage to your other apps with Zapier.',
      placement: 'right'
    },
    trackingCall: () =>
      flowpageEvents.userClickedGatedCRMIntegrations({ variant: CRMLabels.ZAPIER })
  },
  ['view:analytics_export_csv']: {
    header: 'Download a CSV of your Flowcode and Flowpage analytics',
    bg: LockModalProGraphicBG,
    trackingCall: () =>
      flowlyticsEvents.Flowlytics_Native_Analytics_Clicked_Gate({ feature: 'export_csv' })
  },
  ['view:analytics_export_pdf']: {
    header: 'Download a PDF of your Flowcode and Flowpage analytics',
    bg: LockModalProGraphicBG,
    trackingCall: () =>
      flowlyticsEvents.Flowlytics_Native_Analytics_Clicked_Gate({ feature: 'export_pdf' })
  },
  ['view:analytics_top_codes_chart']: {
    header: 'See which codes performed well over your filtered time period',
    bg: LockModalProGraphicBG,
    tooltipContent: {
      id: 'flowlytics-top-performing-codes-chart',
      title: 'Top Performing Codes',
      body: 'See which codes performed well over your filtered time period',
      placement: 'left'
    },
    trackingCall: () =>
      flowlyticsEvents.Flowlytics_Native_Analytics_Clicked_Gate({
        feature: 'top_performing_codes'
      })
  },
  ['view:analytics_top_pages_chart']: {
    header: 'See which pages performed well over your filtered time period',
    bg: LockModalProGraphicBG,
    tooltipContent: {
      id: 'flowlytics-top-performing-pages-chart',
      title: 'Top Performing Pages',
      body: 'See which pages performed well over your filtered time period',
      placement: 'left'
    },
    trackingCall: () =>
      flowlyticsEvents.Flowlytics_Native_Analytics_Clicked_Gate({
        feature: 'top_performing_pages'
      })
  },
  ['view:analytics_top_tags_chart']: {
    header: 'See which tags performed well over your filtered time period',
    bg: LockModalProGraphicBG,
    tooltipContent: {
      id: 'flowlytics-top-performing-tags-chart',
      title: 'Top Performing Tags',
      body: 'See which tags performed well over your filtered time period',
      placement: 'left'
    },
    trackingCall: () =>
      flowlyticsEvents.Flowlytics_Native_Analytics_Clicked_Gate({
        feature: 'top_performing_tags'
      })
  },
  ['view:analytics_top_destinations_chart']: {
    header: 'See which scan destinations receive the most traffic',
    bg: LockModalProGraphicBG,
    tooltipContent: {
      id: 'flowlytics-top-performing-destinations-chart',
      title: 'Top Performing Scan Destinations',
      body: 'See which scan destinations receive the most traffic',
      placement: 'left'
    },
    trackingCall: () =>
      flowlyticsEvents.Flowlytics_Native_Analytics_Clicked_Gate({
        feature: 'top_performing_destinations'
      })
  },
  ['view:analytics_traffic_chart']: {
    header: 'Understand how your links and codes performed over the week, UTM tracking, and more',
    bg: LockModalProGraphicBG,
    tooltipContent: {
      id: 'flowcode-analytics-most-popular-traffic-charts',
      title: 'Traffic',
      body: 'Understand how your links and codes performed over the week, by device type, and more',
      placement: 'left'
    },
    trackingCall: () =>
      flowlyticsEvents.Flowlytics_Native_Analytics_Clicked_Gate({
        feature: 'traffic_chart'
      })
  },
  ['view:analytics_top_links_chart']: {
    header: 'See which links performed well over your filtered time period',
    bg: LockModalProGraphicBG,
    tooltipContent: {
      id: 'flowcode-analytics-top-performing-codes-chart',
      title: 'Top Performing Links',
      body: 'See which links performed well over your filtered time period',
      placement: 'left'
    },
    trackingCall: () =>
      flowlyticsEvents.Flowlytics_Native_Analytics_Clicked_Gate({
        feature: 'top_performing_links'
      })
  },
  ['view:analytics_utm_charts']: {
    header: 'View the effectiveness of your UTM campaigns, terms, and referrers',
    bg: LockModalProGraphicBG,
    tooltipContent: {
      id: 'flowlytics-flowpage-utm-traffic-chart',
      title: 'UTM Traffic',
      body: 'View the effectiveness of your UTM campaigns, terms, and referrers',
      placement: 'left'
    },
    trackingCall: () =>
      flowlyticsEvents.Flowlytics_Native_Analytics_Clicked_Gate({
        feature: 'utm_traffic'
      })
  },
  ['view:analytics_advanced_geo_map']: {
    header: `for advanced geolocation data`,
    src: DemoModalGeolocation,
    bg: '',
    tooltipContent: {
      id: 'flowcode-analytics-geography-charts',
      title: 'Geography',
      body: 'Use our interactive map to see scans across a wide range of filters',
      placement: 'left'
    },
    trackingCall: () =>
      flowlyticsEvents.Flowlytics_Native_Analytics_Clicked_Gate({ feature: 'geo_chart' })
  },
  ['view:link_style_options']: {
    header:
      'Unlock advanced color and font styling options for individual links to better align your Flowpage with your brand',
    src: LockModalFPProPlusLinkLevelStyling,
    bg: LockModalProGraphicBG,
    trackingCall: flowpageEvents.Flowpage_PageProfile_Clicked_Link_Level_Customization_Options,
    tooltipContent: {
      id: 'link-level-styling-tooltip',
      title: 'Customize your link’s look and feel',
      body: 'You can change your link’s border, background, text and shade colors to match your brand.',
      placement: 'right'
    }
  },
  ['create:flowpage_template']: {
    header: 'Unlock the best way to manage many pages at scale',
    bg: LockModalProGraphicBG,
    src: LockModalFPProPlusPageTemplates
  },
  ['view:flowpage_paid_crm']: {
    bg: LockModalProGraphicBG,
    tooltipContent: {
      id: 'pro-external-collection-tooltip',
      title: 'Custom Terms and Conditions',
      body: 'Upgrade to link to your terms and conditions or a PDF.',
      placement: 'right'
    }
  },
  ['view:domains_page']: {
    header: 'Take maximum control of your branding',
    src: LockModalCoreProPlusDomains,
    bg: LockModalProGraphicBG,
    tooltipContent: {
      id: 'custom-subdomains',
      title: 'Whitelabel domains',
      body: 'Add custom domains and subdomains to host your codes and pages.',
      placement: 'right'
    },
    trackingCall: miscEvents.Core_FlowcodeOrFlowpage_Clicked_WhiteLabelDomainDropdownLocked
  },
  ['view:flowteams']: {
    header: 'Collaborate on your QR projects with your team',
    customHeadingText: `Upgrade to Pro Plus for Flowteams`,
    src: ProPlusFlowteams,
    bg: LockModalProGraphicBG
  },
  ['view:asset_permissions']: {
    header: 'Collaborate on your QR projects with your team',
    customHeadingText: `Upgrade to Pro Plus for Flowteams`,
    src: ProPlusFlowteams,
    bg: LockModalProGraphicBG
  },
  ['view:hubspot_crm_option']: {
    header: `to manage your CRM with Hubspot`,
    src: DemoModalHubspotCRM,
    bg: '',
    tooltipContent: {
      id: 'hubspot-crm-tooltip',
      title: 'Hubspot CRM',
      body: 'Upgrade to connect CRM data that you collect with Flowpage directly to your Hubspot instance.',
      placement: 'right'
    },
    trackingCall: () =>
      flowpageEvents.userClickedGatedCRMIntegrations({ variant: CRMLabels.HUBSPOT })
  },
  ['view:interstitial_geolocation']: {
    header: `for advanced geolocation data`,
    src: DemoModalGeolocation,
    bg: '',
    trackingCall: miscEvents.Core_FlowcodeOrFlowpage_Clicked_GeolocationLock
  },
  ['get_more_storage']: {
    header: '',
    src: '/graphics/lockModal/fc-pro-code-limit3-2x.png',
    height: 663,
    width: 305,
    bg: '#242424',
    hideCompanies: true,
    [PlanType.PRO_FLEX]: {
      title: 'Congrats on all those codes and pages!',
      description: () => 'To get more and access next-level features, contact sales.',
      src: '/graphics/lockModal/growth-modal.png',
      href: isAdmin =>
        isAdmin
          ? { pathname: Routes.BUY_PLAN_PAGE, query: { planType: PlanType.PRO_FLEX } }
          : undefined,
      cta: isAdmin => (isAdmin ? 'Upgrade' : undefined)
    },
    [PlanType.ENTERPRISE]: {
      title: 'Congrats on all those codes and pages!',
      description: () => 'To get more and access next-level features, contact sales.',
      src: '/graphics/lockModal/enterprise-modal.png'
    }
  },
  ['code_limit']: {
    header: '',
    customHeadingText: `You have hit your Flowcode limit!`,
    src: '/graphics/lockModal/fc-pro-code-limit3-2x.png',
    height: 663,
    width: 305,
    bg: '#242424',
    hideCompanies: true,
    [PlanType.PRO_FLEX]: {
      title: 'Congrats on all those codes!',
      description: (codeLimit, _, isAdmin) =>
        isAdmin
          ? `Your org has reached the limit for Flowcodes (${codeLimit}). To get more and access to next-level features, upgrade now.`
          : `Your org has reached the limit for Flowcodes (${codeLimit}). To get more and access to next-level features, contact sales.`,
      src: '/graphics/lockModal/growth-modal.png',
      href: isAdmin =>
        isAdmin
          ? { pathname: Routes.BUY_PLAN_PAGE, query: { planType: PlanType.PRO_FLEX } }
          : undefined,
      cta: isAdmin => (isAdmin ? 'Upgrade' : undefined)
    },
    [PlanType.ENTERPRISE]: {
      title: 'Congrats on all those codes!',
      description: (codeLimit, _) =>
        `Your org has reached the limit for Flowcodes (${codeLimit}). To get more and access to next-level features, contact sales.`,
      src: '/graphics/lockModal/enterprise-modal.png'
    }
  },
  ['page_limit']: {
    header: '',
    customHeadingText: `You have hit your Flowpage limit!`,
    src: '/graphics/lockModal/fc-pro-code-limit3-2x.png',
    height: 663,
    width: 305,
    bg: '#242424',
    hideCompanies: true,
    [PlanType.PRO_FLEX]: {
      title: 'Congrats on all those pages!',
      description: (_, pageLimit, isAdmin) =>
        isAdmin
          ? `Your org has reached the limit for Flowpages (${pageLimit}). To get more and access to next-level features, upgrade now.`
          : `Your org has reached the limit for Flowpages (${pageLimit}). To get more and access to next-level features, contact sales.`,
      src: '/graphics/lockModal/growth-modal.png',
      href: isAdmin =>
        isAdmin
          ? { pathname: Routes.BUY_PLAN_PAGE, query: { planType: PlanType.PRO_FLEX } }
          : undefined,
      cta: isAdmin => (isAdmin ? 'Upgrade' : undefined)
    },
    [PlanType.ENTERPRISE]: {
      title: 'Congrats on all those pages!',
      description: (_, pageLimit) =>
        `Your org has reached the limit for Flowpages (${pageLimit}). To get more and access to next-level features, contact sales.`,
      src: '/graphics/lockModal/enterprise-modal.png'
    }
  },
  ['page_limit_free_and_trial']: {
    header: '',
    customHeadingText: `Upgrade to ${PlanDisplayNames['PRO']} to use Flowpage!`,
    src: '/graphics/lockModal/fc-pro-code-limit3-2x.png',
    height: 663,
    width: 305,
    bg: '#242424',
    hideCompanies: true
  }
}
